<template>
  <form class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Lista de Tarefas ({{tasks.length}})</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <table class="u-table table-v2 full-width" style="min-height: auto">
        <thead>
        <tr>
          <th class="text-left" style="max-width: 40px; width: 40px">ID</th>
          <th class="text-left">Tarefa</th>
          <th class="text-left" style="width: 40px">Fila</th>
          <th style="width: 100px" class="text-left">Prazo</th>
          <th style="width: 80px" class="text-left">Horas</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="task in tasks" :key="task.id">
          <td style="max-width: 40px">
            <div class="no-select">{{task.id}}</div>
            <u-tooltip :offset="[2,2]" anchor="bottom left" self="top left">
              <div v-if="task.project">Projeto: {{task.project.name}}</div>
            </u-tooltip>
          </td>
          <td class="td-limit l-200">
            <a @click="gotoTask(task)">{{task.description}}</a>
          </td>
          <td style="max-width: 40px">
            <div v-if="task.queuePosition">
              {{task.queuePosition}}
            </div>
            <div v-else>-</div>
          </td>
          <td style="width: 100px">{{task.deadline|formatDate}}</td>
          <td style="width: 80px">{{task.workedHours}} <i class="fal fa-clock" /></td>
        </tr>
        </tbody>
      </table>
    </div>
<!--    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn
            @click="gerar"
            :loading="loading" type="button" label="Emitir" no-caps
            :color="'green'"
            class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>-->
  </form>
</template>

<script>

export default {
  name: 'TaskListWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  components: {
  },
  props: ['id', 'options', 'router', 'tasks'],
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    gotoTask (t, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'fenaju.tarefa', params: {id: t.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
